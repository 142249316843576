<template>
  <section class="room">
    <div class="card">
      <section class="images">
        <vueper-slides fixed-height="100%" :bullets="false" class="image">
          <vueper-slide
              v-for="(slide, i) in hotel.images"
              :key="i"
              :image="slide">
          </vueper-slide>
        </vueper-slides>
      </section>
      <div v-if="hotel.origin == events" class="plan-room-type">
        <p class="room-type">{{site.events_room_info_title}}</p>
        <p class="room-text" v-html="site.events_room_info_description"></p>
      </div>
      <div v-if="hotel.origin == polaris" class="plan-room-type">
        <p class="room-type">{{site.polaris_room_info_title}}</p>
        <p class="room-text" v-html="site.polaris_room_info_description"></p>
      </div>
      <div class="price">
        <div v-if="dataHotelsIsReady" class="per-night">
          <p>{{ $t('averagePriceNight') }}</p>
          <p>{{ this.hotel.average_day_price.toFixed(2) }}{{ this.site.currency_symbol }}</p>
          <span class="best-deal">{{ $t('bestDeal') }}</span>
        </div>
      </div>
      <a :href="hotel.booking_url" target="_blank" rel="noopener noreferrer" class="btn btn-primary">{{ $t('continue') }}</a>
    </div>
  </section>

  <div id="modal" class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
            <a href="#!" role="button" class="modal-default-button">
              <img src="./../../assets/list-page/bt_map_close_map_default.svg" alt="" srcset="">
            </a>
            <h3>{{ $t('generalConditions') }}</h3>
        </div>
        <div class="modal-body">
          <p v-html="site.general_terms"></p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Constants from '@/constants'

export default {
  components: {
    VueperSlides,
    VueperSlide
  },
  props: {
    hotel: Object
  },
  data: function () {
    return {
      people: 1,
      events: Constants.EVENTS,
      polaris: Constants.POLARIS,
    }
  },
  computed: {
    ...mapGetters(['site', 'dataHotelsIsReady']),
  },
  methods: {
    showIncludedRates(rate) {
      if (rate === 'breakfast') {
        return 'Breakfast included'
      } else if (rate === 'vat10') {
        return 'VAT included'
      } else if (rate === 'touristCityTax') {
        return 'Tourist tax included'
      }
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 767px) {
  .vueperslides__parallax-wrapper{
    height: 200px !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/color-mixin.scss";

.room {
  margin: 0;

  & > p {
    margin: 0 20px;
  }

  p {
    margin: 0 20px;
    color: $secondary-black;
  }

  span.best-deal {
    background: var(--primary-color);
    border-radius: 14px;
    font-size: 14px;
    color: #FFFFFF;
    padding: 0 16px;
    margin-left: 16px;
    height: 22px;
    display: inline-block;
    line-height: 22px;
  }

  .card {
    margin: 0 10px 40px 10px;
    min-height: 430px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;

    .images {
      margin: 0 0 20px 0;

      img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    .price {

      .per-night {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        p {
          font-weight: 600;
          font-size: 16px;
          align-self: center;
        }

        p:last-child {
          font-weight: bold;
          font-size: 26px;
        }

        span.best-deal {
          display: none;
        }

      }

      .total {
        display: flex;
        justify-content: space-between;

        .rooms-nights {
          font-size: 14px;

          p:first-child {
            text-transform: uppercase;
            font-weight: bold;
          }
        }

        p.price-calc {
          font-weight: bold;
          font-size: 30px;
          align-self: center;
        }
      }

      .total.lg {
        display: none;
      }
    }

    a.btn.btn-primary {
      line-height: 40px;
      height: 40px;
      color: white;
      margin: 0 20px 30px 20px;
      display: block;
      background-color: var(--primary-color);

      &:hover {
        background-color: var(--hover-color);
      }
    }

    p.more-info {
      margin: 0 20px;
      font-size: 14px;
      font-weight: bold;
      text-align: right;

      &::after {
        content: url('../../assets/filters-icons/ic_button_deactive_dropdown_active_down.svg');
      }
    }

    .reservation-info {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .price{
    margin: 30px 20px 30px 20px;
    border-top: 1px solid #E9E9E9;

    .per-night{
      margin-top: 20px;
    }
  }
  .plan-room-type{

    .room-type{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;

      .p{
        font-size: 15px;
        color: $secondary-black;
        line-height: 22px;
        text-align: justify;
        white-space: pre-line;
      }
    }
  }
}

@media (min-width: 768px) {
  .room {

    .card {
      margin-left: 0;
      min-height: 340px;
      display: grid;
      grid-template-columns: 32% 25% 23% 20%;
      grid-template-rows: 25% 35% 40%;
      grid-template-areas:
      "images roomType . reserve"
      "images people details reserve"
      "images VATBreak VATBreak reserve";

      .images {
        grid-area: images;
        width: 380px;
        padding: 30px;
        margin-bottom: 0;

        img {
          height: 280px;
          width: 100%;
        }
      }

      .plan-room-type {
        grid-area: roomType;
        display: block;
        padding-top: 30px;

        .room-type{
          font-weight: 600;
          margin-bottom: 30px;
          font-size: 22px;
          width: 500px;
        }

        .room-text{
            font-size: 18px;
            line-height: 22px;
            white-space: pre;
        }
      }

      .people {
        grid-area: people;
        margin: 0;
        padding-bottom: 50px;
        padding-right: 75px;
        align-self: end;
        align-items: center;
        border: none;

        p {
          font-size: 20px;
        }

        img {
          margin-left: 0;
        }
      }

      .room-info {
        display: block;
        grid-area: details;
        align-self: end;
        padding-bottom: 7px;
        padding-left: 80px;
        border-left: 1px solid #E9E9E9;

        p {
          font-size: 20px;
        }

        a {
          text-decoration: none;
          color: $primary-black;
          font-weight: bold;

          &::after {
            content: url('../../assets/hotel-detail/ic_button_deactive_dropdown_active_right.svg');
            margin-left: 10px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .price {
        grid-area: reserve;
        border-left: 1px solid #E9E9E9;
        margin: 0 20px 30px 20px;

        div.per-night {
          flex-direction: column-reverse;
          align-content: flex-end;

          p:first-child {
            font-size: 14px;
            font-weight: 600;
          }

          p {
            align-self: flex-end;
            font-weight: bold;
            font-size: 26px;
          }

          span.best-deal {
            display: block;
            min-width: 107px;
            min-height: 28px;
            font-weight: bold;
            line-height: 28px;
            text-align: center;
            align-self: flex-end;
            margin-top: 40px;
            margin-bottom: 10px;
          }
        }

        .total {
          display: none;
        }

        .total.lg {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p:first-child {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 18px;
          }

          p.price-calc {
            align-self: flex-end;
            font-size: 33px;
          }

          p:last-child {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
      }

      a.btn.btn-primary {
        grid-area: reserve;
        align-self: end;
        justify-self: end;
        margin-left: 60px;
        margin-right: 20px;
      }

      .more-info {
        display: none;
      }

      .reservation-info {
        display: block;
        grid-area: VATBreak;
        border-top: 1px solid #E9E9E9;
        margin-top: 28px;
        padding-top: 28px;

        p:before {
          margin-right: 5px;
          vertical-align: sub;
          content: var(--icon-circular-check);
        }
      }
    }
  }
}

#modal {
opacity: 0;
visibility: hidden;

  &:target {
  opacity: 1;
  visibility: visible;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 768px;
  max-height: 800px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow:scroll;
  overflow-x:hidden;
}

.modal-header {
  border-bottom: 1px solid #EFEFEF;
  position: relative;
  a {
    padding: 0;
    position: absolute;
    left: -25px;
    top: -15px;
    background: white;
    border: 1px solid #EFEFEF;
    border-radius: 50%;
    box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
  }
}

.modal-header h3 {
  margin-top: 0;
  text-align: center;
  color:var(--primary-color);
}

.modal-body {
  text-align: justify;
  margin: 20px 0;
  p {
    padding: 5px 0
  }
}

.modal-default-button {
  float: left;
}

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
